<template>
  <input type="checkbox" class="checkbox-handler" :id="id" v-model="value">
  <label class="checkbox" :class="{ 'not-clickable': notClickable }" :for="id" v-bind="$attrs">
    <i class="icon nicon-checked"></i>
  </label>
</template>

<script setup>
const props = defineProps({
  modelValue: Boolean,
  notClickable: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])

const id = getCurrentInstance().uid
const value = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
</script>

<style scoped lang="scss">
.checkbox {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  border: 1px solid var(--color-elements-tertiary);
  border-radius: 4px;
  color: transparent;
  .checkbox-handler:checked + & {
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-bg);
  }
  &.not-clickable {
    pointer-events: none;
  }
}

.checkbox-handler {
  display: none;
}
</style>
